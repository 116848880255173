import { getExpressionParser } from '@nuclicore/core';

export const createRedirector = () => {
    const expressionParser = getExpressionParser();

    const execute = async (node) => {
        const nodeInfo = node.info.data;
        const url = (await expressionParser.parse(nodeInfo.url, 'strip')).value;
        const shouldOpenInNewTab = nodeInfo?.isOpenInNewTabDynamic ? !!(await expressionParser.parse(nodeInfo.openInNewTab, 'strip')).value : nodeInfo.openInNewTab;
        const target = shouldOpenInNewTab ? '_blank' : '_self';
        window.open(url, target);
    };

    return {
        execute
    };
};
