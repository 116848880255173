// @ts-check
import { getWorkflowEngine } from '@nuclicore/core';
import { createMonitoringLogService } from '@/services/monitoring-service/logRequests';
import { useApplicationStore } from '@/store/applicationStore';
import { pinia } from '@/plugins/pinia';
import { createDataMapper } from '@/config/workflow-engine/jobs/dataMapper';
import { createApiConnector } from '@/config/workflow-engine/jobs/apiConnector';
import { createVariableSetter } from '@/config/workflow-engine/jobs/variableSetter';
import { createRedirector } from '@/config/workflow-engine/jobs/redirector';

export const workflowEngine = getWorkflowEngine();

const applicationStore = useApplicationStore(pinia);

// workflow jobs
const apiConnector = createApiConnector();
const dataMapper = createDataMapper();
const variableSetter = createVariableSetter();
const redirector = createRedirector();

const handleExecutingNode = async (node) => {
    const nodeExecutionMapper = {
        'Send API Request': apiConnector.execute,
        'Map/Pair Data': dataMapper.execute,
        'Set Variable': variableSetter.execute,
        'Redirect': redirector.execute
    };
    const handler = nodeExecutionMapper[node.data.type];
    try {
        if (handler) {
            const result = await handler(node);
            const nodeInfo = node?.info?.data;
            if (result?.level && nodeInfo) {
                /** @type {import('@/services/monitoring-service/monitoringServiceTypes').ILogPayload} */
                const logPayload = {
                    application_id: nodeInfo.application_id,
                    module_id: nodeInfo.module_id,
                    session_id: applicationStore.executionResult.session, 
                    type: result.level,
                    title: result.data.title,
                    message: result.data.message,
                    environment: applicationStore.deploymentInfo.environment_name
                };
                await createMonitoringLogService(logPayload);
            }
        }
    } catch (err) {
        console.error(err);
    }
};

workflowEngine.on('execute', handleExecutingNode);
workflowEngine.on('skip', () => {});

