import axios from 'axios';
import { getExpressionParser } from '@nuclicore/core';
import { createDataMapper } from '@/config/workflow-engine/jobs/dataMapper';
import { useWorkflowStore } from '@/store/workflowStore';
import { pinia } from '@/plugins/pinia';

export const createApiConnector = () => {
    const expressionParser = getExpressionParser();
    const dataMapper = createDataMapper();
    const workflowStore = useWorkflowStore(pinia);

    const execute = async (node) => {
        const nodeInfo = node.info.data;

        const { value: url } = await expressionParser.parse(nodeInfo.url, 'strip');
        let { value: body } = await expressionParser.parse(nodeInfo.body, 'unwrap');
        const queryParameters = await parseQueryParams(nodeInfo.parameters);
        let headers = await parseHeaders(nodeInfo);

        const contentType = headers['Content-Type'] || '';

        let formData = null;

        if (body && typeof body === 'string') {
            body = JSON.parse(body);
            if (contentType.includes('multipart/form-data')) {
                // @TO-Do's: Pending different implementation for a browser environment
                // formData = await dataMapper.buildFormData(session, node, body, environmentName);
            }
        }

        headers = {
            ...headers,
            ...(formData?.getHeaders() || {})
        };

        const meta = {
            url: url,
            body: nodeInfo.body
        };

        let response = null;
        let result = null;

        try {
            response = await axios({
                method: nodeInfo.method,
                url: url,
                headers,
                params: queryParameters,
                data: formData || body
            });
            result = {
                level: 'info',
                data: {
                    action: 'Send API request',
                    title: `HTTP request to ${url} was successful`,
                    message: ''
                }
            };
        } catch (error) {
            console.error(error);
            response = error.response;
            result = {
                level: 'error',
                data: {
                    action: 'Send API request',
                    title: `HTTP request to ${url} failed`,
                    message: ''
                }
            };
        } finally {
            meta.response = JSON.stringify(response.data || {});
            meta.status = response.status;
            result.data.message = JSON.stringify(meta);
            workflowStore.updateWorkflowVariable(`${node.nodeId}_httpResponse`, {
                value: meta.response,
                isValueIgnored: false
            });
            workflowStore.updateWorkflowVariable(`${node.nodeId}_httpResponseStatus`, {
                value: meta.status,
                isValueIgnored: false
            });
        }

        return result;
    };

    const parseQueryParams = async (rawParams) => {
        const queryParams = {};
        for (const param of rawParams) {
            const { value: paramName } = await expressionParser.parse(param.name, 'strip');
            const { value: paramValue } = await expressionParser.parse(param.value, 'strip');
            if (paramName && paramValue) {
                queryParams[paramName] = (await expressionParser.parse(param.value, 'strip')).value;
            }
        }
        return queryParams;
    };

    const parseHeaders = async (nodeInfo) => {
        // parse header values
        const headers = {};
        for (const header of nodeInfo.headers) {
            if (header.name.replace(/<[^>]*>?/gm, '').trim() !== '') {
                headers[header.name.replace(/<[^>]*>?/gm, '').trim()] = (await expressionParser.parse(header.value, 'strip')).value;
            }
        }

        // parse auth
        const auth = {
            type: '',
            credentials: ''
        };
        if (nodeInfo.authentication?.type ==='Basic Auth') {
            auth.type = 'Basic';
            const { username, password } = nodeInfo.authentication.data;
            const { value: parsedUserName } = await expressionParser.parse(username, 'strip');
            const { value: parsedPassword } = await expressionParser.parse(password, 'strip');
            auth.credentials = Buffer.from(`${parsedUserName}:${parsedPassword}`).toString('base64');
        }
        if (auth.type && !('Authorization' in headers)) {
            // add authentication option from API connector node only if Authorization header is not provided
            headers.Authorization = `${auth.type} ${auth.credentials}`;
        }

        return headers;
    };

    return {
        execute
    };
};
